import React from "react";
import { NavLink } from "react-router-dom";

const CardFooter = ({ title, datas }) => {
  return (
    <div>
      <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase">
        {title}
      </h2>
      {datas?.map((data, index) => (
        <ul key={index} className="text-gray-500">
          <li className="mb-2 text-sm">
            <NavLink to={data.link} className="hover:underline">
              {data.name}
            </NavLink>
          </li>
        </ul>
      ))}
    </div>
  );
};

export default CardFooter;
