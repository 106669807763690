import React, { createContext, useState } from "react";
import { useAddContactMutation } from "../../../api/contactpartenaire/hooks";
import { toast } from "react-toastify";

export const ContactContext = createContext();

const ContactContextProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [showModalThree, setShowModalThree] = useState(false);
  const [numero, setNumero] = useState("");
  const [numeroResponsable, setNumeroResponsable] = useState("");
  const dateFormatte = new Date().toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    adresse_id: 1,
    date_creation: dateFormatte,
    date_modification: dateFormatte,
    description_etablissement: "",
    mail: "",
    numero: "",
    metier: "",
    nom: "",
    nom_etablissement: "",
    position_hierarchique: "",
    prenom: "",
    rendez_vous: "",
    type: "",
  });
  const { mutateAsync: addNewContact } = useAddContactMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const validateForm = () => {
    if (
      !formState.nom ||
      !formState.mail ||
      !numero ||
      !formState.prenom ||
      !formState.position_hierarchique ||
      !formState.nom_etablissement ||
      !formState.metier ||
      !formState.description_etablissement
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formState.mail)) {
      toast.error("L'adresse e-mail est invalide.");
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setFormState({
      adresse_id: 1,
      date_creation: dateFormatte,
      date_modification: dateFormatte,
      description_etablissement: "",
      mail: "",
      numero: "",
      metier: "",
      nom: "",
      nom_etablissement: "",
      position_hierarchique: "",
      prenom: "",
      rendez_vous: "",
      type: "",
    });
    setNumero("");
    setNumeroResponsable("");
    setShowModalThree(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const payload = {
      ...formState,
      numero,
      date_creation: dateFormatte,
      date_modification: dateFormatte,
      type: "Partenaire",
    };

    try {
      setIsLoading(true);
      const response = await addNewContact(payload);
      if (response.data) {
        resetForm();
        toast.success("Contact ajouté avec succès !");
      } else {
        toast.error("Une erreur s'est produite.");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response?.data?.message || "Une erreur inattendue est survenue."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    showModal,
    showModalTwo,
    showModalThree,
    setShowModal,
    setShowModalTwo,
    setShowModalThree,
    numero,
    setNumero,
    numeroResponsable,
    setNumeroResponsable,
    dateFormatte,
    isLoading,
    setIsLoading,
    formState,
    setFormState,
    handleChange,
    handleSubmit,
  };
  return (
    <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
  );
};

export default ContactContextProvider;
