import { fetchApi } from "../fetchApi";

// Appel GET pour récupérer les locations
export const fetchLocations = () => fetchApi("/partenaire-sports");

// Appel GET pour récupérer une location avec son id
export const fetchLocationById = (slug) => fetchApi(`/partenaire-sports/${slug}`);                 

// Appel POST pour ajouter un nouvel item (par exemple un nouveau partenaire)
export const addNewLocation = (locationData) =>
  fetchApi("/partenaire-sports", "POST", locationData);

// Appel PUT pour mettre à jour un item existant
export const updateLocation = (slug, updatedData) =>
  fetchApi(`/partenaire-sports/${slug}`, "PUT", updatedData);

// Appel DELETE pour supprimer un item
export const deleteLocation = (slug) =>
  fetchApi(`/partenaire-sports/${slug}`, "DELETE");
