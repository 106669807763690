import { useContext } from "react";
import { ContactContext } from "../../contexte/recherche/ContactContext";

const useContacts = () => {
  const useContextContacts = useContext(ContactContext);

  return useContextContacts;
};

export default useContacts;
