import React, { useEffect, useState } from "react";
import ComponentSearch from "./small_components/component_search";
import "leaflet/dist/leaflet.css";
import ScrollableContainer from "./small_components/checkbox_endpoint";
import Invisible_Nonclickable from "./small_components/invisible_nonclickable";
import Activity_endpoint from "./small_components/checkbox_activities";
import { useLocationsQuery } from "../../api/partenaireSport/hooks";
import { apiImgUrl } from "../../http/http";

export default function Search_main({
  setSelectedEnterprise,
  onOpenSearchModal,
  checkboxState,
  onCheckboxChange,
  toggleCheckboxState,
}) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleDivClick = (index, location) => {
    setSelectedEnterprise(location);
    setActiveIndex(index);
  };

  const handleButtonClick = () => {
    console.log("Button clicked!");
  };
  const [categorie, setCategorie] = useState([]);
  const [activity, setActivity] = useState([]);

  const fallbackLocations = [
    {
      title: "RMS Static",
      time: "07:00 - 21:00",
      pNumber: "33 867 00 01",
      location: `15°43'23.2"N 18°28'35., Rue 1, Dakar`,
      quartier: `Ouakam Static`,
      latitude: 14.71128,
      longitude: -17.4567,
    },
    {
      title: "Life Static",
      time: "08:00 - 22:00",
      pNumber: "33 867 00 02",
      location: `16°43'43.2"N 19°29'45., Rue 2, Dakar`,
      latitude: 48.8566,
      longitude: 2.3522,
    },
    {
      title: "Fallback Sport 3",
      time: "06:00 - 19:00",
      pNumber: "33 867 00 03",
      location: `17°43'53.2"N 20°30'55., Rue 3, Dakar`,
      latitude: 35.682839,
      longitude: 139.759455,
    },
    {
      title: "Fallback Sport 3",
      time: "06:00 - 19:00",
      pNumber: "33 867 00 03",
      location: `17°43'53.2"N 20°30'55., Rue 3, Dakar`,
    },
    {
      title: "Fallback Sport 3",
      time: "06:00 - 19:00",
      pNumber: "33 867 00 03",
      location: `17°43'53.2"N 20°30'55., Rue 3, Dakar`,
    },
  ];

  const fallbackImages = [
    "/img/lifefitness.jpg",
    "/img/gym1.webp",
    "/img/fitbox.JPG",
    "/img/lifefitness.jpg",
    "/img/gym1.webp",
    "/img/fitbox.JPG",
  ];

  useEffect(() => {
    const selectedCategories = Object.keys(checkboxState).filter(
      (key) => checkboxState[key]
    );
    setCategorie(selectedCategories);
  }, [checkboxState]);

  useEffect(() => {
    const selectedActivities = Object.keys(checkboxState).filter(
      (key) => checkboxState[key]
    );

    setActivity(selectedActivities);
  }, [checkboxState]);

  const combinedButtonClickHandler = () => {
    handleButtonClick();
    toggleCheckboxState("salle_de_sport");
  };

  function decimalToDMS(degrees) {
    const isNegative = degrees < 0;
    degrees = Math.abs(degrees);

    // Calculer le degré, la minute et la seconde
    const d = Math.floor(degrees);
    const m = Math.floor((degrees - d) * 60);
    const s = ((degrees - d - m / 60) * 3600).toFixed(1);

    const direction = isNegative ? (d < 90 ? "" : "") : d < 90 ? "N" : "E";

    return `${d}°${m}'${s}"${direction}`;
  }

  const { data: partenaires = [], error, isLoading } = useLocationsQuery();

  const [filtre, setFfiltre] = useState("");
  const [villeFilter, setVvilleFilter] = useState("");
  const filtrage = isLoading
    ? []
    : partenaires?.data?.filter((location) => {
        const nom = location.nom || "";
        const address = location.adresse?.ville || "";
        const quartier = location.adresse?.quartier || "";
        const services =
          location.services?.flatMap((service) => service.activite) || [];
        const activiteNoms = services?.map((item) => item.nom || []);
        const activiteSport =
          nom.toLowerCase().includes(filtre.toLowerCase()) ||
          activiteNoms.some((activite) =>
            activite.includes(filtre.toLowerCase())
          );

        const villeQuartier =
          address.toLowerCase().includes(villeFilter.toLowerCase()) ||
          quartier.toLowerCase().includes(villeFilter.toLowerCase());

        //   const categoryMatch = categorie.length
        //   ? categorie.includes(location.categorie.nom)
        //   : true;
        // const activityMatch = activity.length
        //   ? activity.some(
        //       (act) =>
        //         activiteNoms.some(
        //           (activite) => activite.toLowerCase() === act.toLowerCase()
        //         ) || location.categorie.nom.toLowerCase() === act.toLowerCase()
        //     )
        //   : true;
        const activityMatch = activity.length
          ? activity.some((act) => {
              const actLower = act.toLowerCase();
              const categorieLower = location.categorie.nom.toLowerCase();

              return (
                activiteNoms.some((activite) =>
                  activite.toLowerCase().includes(actLower)
                ) || categorieLower.includes(actLower)
              );
            })
          : true;

        return (
          (filtre === "" || activiteSport) &&
          (villeFilter === "" || villeQuartier) &&
          activityMatch
        );
      });
  return (
    <>
      <div className="h-min w-[100%] px-6 flex justify-center gap-[10px] overflow-y-hidden">
        <div className=" w-full grid grid-cols-7  items-center gap-2 overflow-hidden">
          <div className="bg-[#dde3ed] col-span-4 flex items-center rounded-md px-2 p-1 font-quicksand">
            <img src="../search.png" className="w-4 mr-2" />
            <input
              className="text-[12px] text-sm w-full bg-transparent py-1 font-bold placeholder:text-[#828282] placeholder:-mb-[10px] outline-0  "
              placeholder="Salle de sport, ou activité"
              type="text"
              value={filtre}
              onChange={(e) => setFfiltre(e.target.value)}
            />
          </div>
          <div className="bg-[#dde3ed] col-span-3 flex items-center justify-center rounded-md px-2 p-1 font-quicksand">
            <img src="../location.png" className="w-6 " />
            <input
              className="text-[12px] w-full bg-transparent py-1 text-sm font-bold placeholder:text-[#828282] placeholder:-mb-[10px] outline-0  "
              placeholder="Quartiers, Villes"
              type="text"
              value={villeFilter}
              onChange={(e) => setVvilleFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className=" w-[100%]   flex justify-center overflow-hidden">
        <button
          onClick={onOpenSearchModal}
          className="p-1 px-2.5 py-0.5  bg-white border-[#1D428A] border-2 rounded-[15px] mx-auto mt-[20px] flex items-center justify-center gap-[10px]"
        >
          <h4 className="text-[14px] text-main-blue font-bold font-quicksand">
            Filtrer les résultats
          </h4>
          <svg
            width="14"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 2.00001H9.17C9.3766 1.41448 9.75974 0.907443 10.2666 0.548799C10.7735 0.190154 11.3791 -0.00244141 12 -0.00244141C12.6209 -0.00244141 13.2265 0.190154 13.7334 0.548799C14.2403 0.907443 14.6234 1.41448 14.83 2.00001H17C17.2652 2.00001 17.5196 2.10537 17.7071 2.2929C17.8946 2.48044 18 2.73479 18 3.00001C18 3.26523 17.8946 3.51958 17.7071 3.70712C17.5196 3.89465 17.2652 4.00001 17 4.00001H14.83C14.6234 4.58554 14.2403 5.09258 13.7334 5.45122C13.2265 5.80986 12.6209 6.00246 12 6.00246C11.3791 6.00246 10.7735 5.80986 10.2666 5.45122C9.75974 5.09258 9.3766 4.58554 9.17 4.00001H1C0.734784 4.00001 0.48043 3.89465 0.292893 3.70712C0.105357 3.51958 0 3.26523 0 3.00001C0 2.73479 0.105357 2.48044 0.292893 2.2929C0.48043 2.10537 0.734784 2.00001 1 2.00001ZM1 10H3.17C3.3766 9.41448 3.75974 8.90744 4.2666 8.5488C4.77346 8.19015 5.37909 7.99756 6 7.99756C6.62091 7.99756 7.22654 8.19015 7.7334 8.5488C8.24026 8.90744 8.6234 9.41448 8.83 10H17C17.2652 10 17.5196 10.1054 17.7071 10.2929C17.8946 10.4804 18 10.7348 18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8947 17.2652 12 17 12H8.83C8.6234 12.5855 8.24026 13.0926 7.7334 13.4512C7.22654 13.8099 6.62091 14.0025 6 14.0025C5.37909 14.0025 4.77346 13.8099 4.2666 13.4512C3.75974 13.0926 3.3766 12.5855 3.17 12H1C0.734784 12 0.48043 11.8947 0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11C0 10.7348 0.105357 10.4804 0.292893 10.2929C0.48043 10.1054 0.734784 10 1 10Z"
              fill="#1D428A"
            />
          </svg>
        </button>
      </div>
      <div className="h-[60px]  ml-[2.25%] min-w-[700px] scrollbar-hide overflow-x-auto flex gap-[9px]">
        {checkboxState?.salle && (
          <ScrollableContainer
            title="Salle de Sport"
            onButtonClick={() => toggleCheckboxState("salle")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.club && (
          <ScrollableContainer
            title="Club"
            onButtonClick={() => toggleCheckboxState("club")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.cours && (
          <ScrollableContainer
            title="Cours"
            onButtonClick={() => toggleCheckboxState("cours")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.football && (
          <Activity_endpoint
            title="Football"
            onButtonClick={() => toggleCheckboxState("football")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.natation && (
          <Activity_endpoint
            title="Natation"
            onButtonClick={() => toggleCheckboxState("natation")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.badminton && (
          <Activity_endpoint
            title="Badminton"
            onButtonClick={() => toggleCheckboxState("badminton")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.yoga && (
          <Activity_endpoint
            title="Yoga"
            onButtonClick={() => toggleCheckboxState("yoga")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.karate && (
          <Activity_endpoint
            title="Karaté"
            onButtonClick={() => toggleCheckboxState("karate")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.boxe && (
          <Activity_endpoint
            title="Boxe"
            onButtonClick={() => toggleCheckboxState("boxe")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.mma && (
          <Activity_endpoint
            title="MMA"
            onButtonClick={() => toggleCheckboxState("mma")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.gym && (
          <Activity_endpoint
            title="Gym"
            onButtonClick={() => toggleCheckboxState("gym")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.thai && (
          <Activity_endpoint
            title="Muay Thai"
            onButtonClick={() => toggleCheckboxState("thai")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.musculation && (
          <Activity_endpoint
            title="Musculation"
            onButtonClick={() => toggleCheckboxState("musculation")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.jjb && (
          <Activity_endpoint
            title="Jiujitsu 🇧🇷"
            onButtonClick={() => toggleCheckboxState("jjb")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.fitness && (
          <Activity_endpoint
            title="Fitness"
            onButtonClick={() => toggleCheckboxState("fitness")}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.autre && (
          <Activity_endpoint
            title="Autre"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        <Invisible_Nonclickable
          title="Salle de Sport"
          onButtonClick={handleButtonClick}
        />

        <Invisible_Nonclickable
          title="Salle de Sport"
          onButtonClick={handleButtonClick}
        />

        <Invisible_Nonclickable
          title="Salle de Sport"
          onButtonClick={handleButtonClick}
        />
      </div>
      <div className="h-full w-full  px-4 mb-6">
        <div className="max-h-[80vh] overflow-y-scroll overflow-x-hidden w-[100%] scrollable">
          <div className=" w-[100%] overflow-x-hidden flex flex-col gap-[15px] mb-28 mt-2 scrollable">
            {filtrage.length === 0 ? (
              <p className="py-6 px-4">
                Aucun résultat ne correspond à votre recherche.
              </p>
            ) : (
              filtrage.map((location, index) => (
                <div
                  className={`${activeIndex === index ? " " : " pe-2.5"}`}
                  key={index}
                >
                  <ComponentSearch
                    id={location.slug}
                    backgroundImage={
                      location.image
                        ? `${apiImgUrl}/storage/${location.image}`
                        : fallbackImages[index % fallbackImages.length]
                    }
                    Title={
                      location.nom ||
                      fallbackLocations[index % fallbackLocations.length].title
                    }
                    Time={
                      location.horaire ||
                      fallbackLocations[index % fallbackLocations.length].time
                    }
                    pNumber={
                      location.numero ||
                      fallbackLocations[index % fallbackLocations.length]
                        .pNumber
                    }
                    Location={
                      // decimalToDMS(location.adresse.latitude) +
                      //   " " +
                      //   decimalToDMS(location.adresse.longitude, true) +
                      //   ", " +
                      //   location.adresse.rue +
                      //   ", " +
                      location.adresse.rue + ", " + location.adresse.quartier + ", " +  location.adresse.ville ||
                      fallbackLocations[index % fallbackLocations.length]
                        .location
                    }
                    location={location}
                    locations={partenaires?.data}
                    fallbackLocations={fallbackLocations}
                    fallbackImages={fallbackImages}
                    isClicked={activeIndex === index}
                    onDivClick={() => handleDivClick(index, location)}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
