import React from "react";
import { Link } from "react-router-dom";
import useRecherche from "../utils/hooks/recherche/useRecherche";

// Define the ActivityBox component with props for dynamic and static data
export default function ActivityBox({ backgroundImage, text }) {
  const { toggleCheckboxState } = useRecherche();
  return (
    <Link  onClick={() => toggleCheckboxState(text)} to="/salle">
      <div
       
        className="z-0 rounded-[15px] h-[100%] w-[240px] flex items-center cursor-pointer max-sm:h-[90%] max-sm:w-[320px]"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="relative flex items-center justify-center w-full h-[50px]">
          <div className="absolute inset-0 backdrop-blur-[40px] bg-black mix-blend-overlay opacity-40" />
          <div className="relative z-10 flex items-center justify-center w-full h-[50px]">
            <div className="text-white font-r-mono font-bold text-[20px] mt-1 leading-[32px] text-center uppercase">
              {text}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
