import React from "react";

const CardAdvantages = ({advantages}) => {
  return (
    <div className="w-[90%] mx-auto mt-[0.8%] flex flex-col items-center">
      <div className="wrapper w-[99.38%] flex  overflow-y-hidden flex-grow-0 mr-auto  ">
        <div className="h-[305px] w-[1620px] mx-auto relative overflow-y-hidden scrollbar-hide">
          <div className="advantage-container flex flex-grow-0 h-[100%] overflow-hidden w-[2200px] gap-[20px] relative max-2xl:h-[100%]">
            {advantages.map((advantage, index) => (
              <div
                key={index}
                className="relative adv-card bg-main-blue py-4 h-[100%] w-[526px] rounded-[15px] flex-col max-sm:w-[320px]"
              >
                <h1 className="text-white font-semibold text-sm lg:text-[23px] flex items-center h-[50px] font-quicksand px-4  leading-[28px]">
                  {advantage?.titre}
                </h1>
                <div className="white-line h-[0.65%] w-[90.15%] bg-white m-auto mt-[2.35%] "></div>
                <div className="paragraph-card  text-sm text-white text-left font-arial font-[700] leading-[32px] w-[91%] mt-[3.75%] ml-[4%] ">
                  {advantage?.description}
                </div>
                {/* <div className="text-[320px] font-arial bg-red-800 font-semi-bold  text-left text-white text-opacity-10 -mt-[50.25%] ml-[3%] mix-blend-overlay max-sm:-mt-[90%]">
                  {advantage.numero}
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAdvantages;
