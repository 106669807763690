import React, { useEffect, useState } from "react";
import { apiImgUrl } from "../../http/http";
import Loader from "./Loader";

const Carrousel = ({ logos }) => {
  const [show, setShow] = useState(false);
  const datas = logos?.filter((logo) => logo.afficher === 1);

  useEffect(() => {
    setShow(true);
  }, []);

  const shouldScroll = datas?.length > 10;

  return (
    <div className="carousel-container pt-4 overflow w-full relative">
      <div
        className={`carousel-track flex justify-center items-center gap-4 ${
          shouldScroll ? "animate-slide carousel-track " : ""
        }`}
        style={{ "--card-gap": "32px" }}
      >
        {logos && logos?.length > 0 ? (
          datas?.map((data, index) => (
            <div
              key={index}
              className="carousel-item w-24 h-24 flex-shrink-0 bg-white rounded-lg shadow-md border overflow-hidden"
            >
              <img
                src={`${apiImgUrl}/storage/${data.logo}`}
                alt={`Logo ${index + 1}`}
                className="object-contain w-full h-full"
              />
            </div>
          ))
        ) : (
          <p className="text-center py-4 text-gray-500 w-full">
            <Loader />
          </p>
        )}
      </div>
      <style jsx="true">{`
        @media (max-width: 768px) {
          @keyframes slide {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
          .carousel-container {
            --card-gap: 32px;
          }
          .carousel-track {
            animation: slide 10s linear infinite;
          }
        }
      `}</style>
      {shouldScroll && (
        <style jsx="true">{`
          @keyframes slide {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }

          .carousel-container {
            --card-gap: 32px;
          }

          .carousel-track {
            animation: slide 10s linear infinite;
            display: flex;
            flex-wrap: nowrap;
          }

          @media (max-width: 768px) {
            .carousel-item {
              width: 80px;
              height: 80px;
            }
          }

          @media (max-width: 480px) {
            .carousel-item {
              width: 60px;
              height: 60px;
            }
          }
        `}</style>
      )}
    </div>
  );
};

export default Carrousel;
