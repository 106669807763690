import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="logo w-full flex items-center justify-center">
      <Link to={"/"} className="logo w-full flex items-center justify-center">
        <img className="w-1/3 py-2" src="../logo1.png" alt="Logo de ketket" />
      </Link>
    </div>
  );
};

export default Logo;
