import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={"/"} className="logo w-[100px] lg:w-[150px] flex items-center">
      <img className="" src="../logo1.png" alt="Logo de ketket" />
    </Link>
  );
};

export default Logo;
