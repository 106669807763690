import React from "react";

export const TextareaLabel = ({ label, placeholder, value, onChange, name }) => {
  return (
    <div className="w-full md:w-1/2 mb-2">
      <label htmlFor={name} className="block text-sm font-semibold mb-2">
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={5}
        className="bg-[#F6F9FF] placeholder:text-[C8C8C8] ring-1 w-full ring-[#EEF4FF] outline-0 p-2 sm:text-sm md:text-[12px]"
      ></textarea>
    </div>
  );
};
