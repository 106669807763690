import React from "react";

const RightSection = () => {
  return (
    <div className="flex h-[95%] w-full rounded-md justify-end max-lg:justify-center items-center mt-4 max-lg:mt-3">
      <img
        src={"../hero.jpg"}
        alt="Happy Employees"
        className="rounded-md w-[85%] max-lg:w-full h-[100%] shadow-lg object-cover "
      />
    </div>
  );
};

export default RightSection;
