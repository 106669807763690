import React from "react";

const TexteComponent = ({title, content, titleDefault, contentDefault}) => {
  return (
    <>
      <h1 className="text-h4 leading-[39px]  font-bold font-quicksand text-main-blue mt-[16.55%] max-sm:mt-0 max-lg:ml-auto ">
        {title
          ? title
          : titleDefault}
      </h1>

      <div className=" text-h6 text-sm font-quicksand leading-[28px] font-semibold w-[92%]  max-sm:w-[100%] max-sm:text-justify mt-[1.7%]">
        {content  
          ? content
          : contentDefault}
      </div>
    </>
  );
};

export default TexteComponent;
