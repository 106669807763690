import { useQuery } from "@tanstack/react-query";
import { fetchPartenaireBySlug, fetchPartenaires } from "./api";

// Hook pour récupérer les Clients
export const usePartenairesQuery = () => {
  return useQuery({
    queryKey: ["partenaires"],
    queryFn: fetchPartenaires,
    // staleTime: 1000 * 60 * 10, 
    // cacheTime: 1000 * 60 * 30, 
    // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
  });
};

// Hook pour récupérer un partenaire par slug
export const usePartenaireQuery = (slug) => {
  return useQuery({
    queryKey: ["partenaire", slug],
    queryFn: () => fetchPartenaireBySlug(slug),
    enabled: !!slug,
    staleTime: 1000 * 60 * 10, 
    cacheTime: 1000 * 60 * 30, 
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
