import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewSubcription } from "./api";

export const useAddNewsletterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addNewSubcription,
    onSuccess: () => {
      queryClient.invalidateQueries(["newsletters"]);
    },
  });
};
