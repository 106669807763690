import { useContext  } from "react";
import { RechercheContext } from "../../contexte/recherche/RechercheContext";

const useRecherche = () => {
  const useContextRecherche = useContext(RechercheContext);

  return useContextRecherche;
};

export default useRecherche;
