import React from "react";
import CheckboxItem from "./checkbox_component";

export const CheckboxGroup = ({
  title,
  checkboxes,
  checkboxState,
  onCheckboxChange,
}) => (
  <div className="flex flex-col mt-3">
    <h6 className="text-[12px] font-bold leading-p text-black font-quicksand">
      {title}
    </h6>
    <div className="grid grid-cols-4 items-center flex-wrap mt-1 gap-1">
      {checkboxes.map(({ id, label }) => (
        <CheckboxItem
          key={id}
          id={id}
          label={label}
          checkboxState={checkboxState}
          onCheckboxChange={onCheckboxChange}
        />
      ))}
    </div>
  </div>
);


