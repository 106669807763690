import { useContext } from "react";
import { ClientContext } from "../../contexte/contactClient/ClientContext";

const useClient = () => {
  const useContextClient = useContext(ClientContext);

  return useContextClient;
};

export default useClient;
