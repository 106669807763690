import React from "react";

export const SelectComponent = ({ name, children, options, value, onChange }) => {
  return (
    <>
      <label className="form-label mb-2 text-sm " htmlFor={name}>
        {children}
      </label>
      <select
        id={name}
        value={value}
        onChange={onChange}
        className="rounded-sm font-semibold border-0 text-[12px] ring-1 ring-[#EEF4FF] outline-0 py-2.5 px-2 bg-[#F6F9FF] text-[#000]  placeholder:text-[#000]  sm:text-sm md:text-[12px] sm:leading-6"
        name={name}
      >
        {options.map((option, index) => (
          <option className="font-semibold" key={index}>{option}</option>
        ))}
      </select>
    </>
  );
};
