import React from "react";
import Form from "./Form";
import { FormSport } from "./FormSport";
import useClient from "../../utils/hooks/clients/useClient";
import useContacts from "../../utils/hooks/recherche/useContacts";

const FormSportPartenaire = () => {
  const { isLoading, handleSubmit } = useClient();
  return (
    <Form
      onSubmit={handleSubmit}
      FormContainer={<FormSport />}
      isLoading={isLoading}
    />
  );
};

export default FormSportPartenaire;
