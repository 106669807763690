import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchContacts,
  addNewContact,
  updateContact,
  deleteContact,
} from "./api"; // Import des fonctions API

// Hook pour récupérer les Contacts
export const useContactsQuery = () => {
  return useQuery({
    queryKey: ["contacts"],
    queryFn: fetchContacts,
  });
};

// Hook pour ajouter une nouvelle contact
// export const useAddContactMutation = () => {
//   const queryClient = useQueryClient();

//   return useMutation(addNewContact, {
//     onSuccess: () => {
//       // Invalidate and refetch Contacts after a successful mutation
//       queryClient.invalidateQueries(["contacts"]);
//     },
//   });
// };

export const useAddContactMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addNewContact,
    onSuccess: () => {
      queryClient.invalidateQueries(["contacts"]);
    },
  });
};

// Hook pour mettre à jour une contact
export const useUpdateContactMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(({ id, updatedData }) => updateContact(id, updatedData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["contacts"]);
    },
  });
};

// Hook pour supprimer une contact
export const useDeleteContactMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteContact(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["contacts"]);
    },
  });
};
