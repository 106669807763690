import React, { useState } from "react";
import InputLabel from "./InputLabel";
import { TextareaLabel } from "./TextareaLabel";
import { SelectComponent } from "./SelectComponent";
import InputPhone from "./InputPhone";

export const FormCollaborateur = ({
  formState,
  handleChange,
  phone,
  setPhone,
}) => {
  
  const options = [
    "select",
    "1 a 10",
    "de 11 á 50",
    "de 51 á 100",
    "de 101 á 201",
    "de 201 á 500",
    "de 501 á 1000",
    "de 1001 á 1500",
    "plus de 100",
  ];
  return (
    <>
      <div className="mb-0 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="prenom"
          value={formState.prenom}
          onChange={handleChange}
          children="Prenom"
        />
        <InputLabel
          type="text"
          name="nom_etablissement"
          value={formState.nom_etablissement}
          onChange={handleChange}
          children="Noms de votre entreprise"
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="nom"
          value={formState.nom}
          onChange={handleChange}
          children="Nom"
        />
        <InputLabel
          type="email"
          name="mail_responsable"
          children="E-mail du RH ou du Responsable "
          value={formState.mail_responsable}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="email"
          name="mail"
          value={formState.mail}
          onChange={handleChange}
          children="E-mail"
        />

        <div className="flex flex-col w-full md:w-1/2">
          <SelectComponent
            name="effectif"
            children="Nombre d'employés"
            options={options}
            value={formState.effectif}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <div className="mb-2 sm:flex flex-col w-full md:w-1/2 gap-3 ">
          {/* <InputLabel
            type="email"
            name="email"
            //   value={state.email}
            //   onChange={handleChange}
            children="Email"
          /> */}
          <InputPhone
            phone={phone}
            setPhone={setPhone}
            label={"Numéro de téléphone"}
          />
          <div className="flex flex-col w-full">
            {/* <SelectComponent
              name="date"
              children="Rendez-vous"
              options={options}
              type={'date'}
              // value={}
            /> */}
            <InputLabel
              type="date"
              name="rendez_vous"
              value={formState.rendez_vous}
              onChange={handleChange}
              children="Rendez-vous"
            />
          </div>
        </div>
        <TextareaLabel
          label="Description de votre entreprise"
          placeholder="Message"
          value={formState.description_etablissement}
          onChange={handleChange}
          name="description_etablissement"
        />
      </div>
    </>
  );
};
