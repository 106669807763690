import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  Popup,
  useMap, // Importation de useMap pour manipuler la carte
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useLocationsQuery } from "../../api/partenaireSport/hooks";
import { apiImgUrl } from "../../http/http";
import {ScaleLoader} from "react-spinners";

const SetViewOnChange = ({ positions }) => {
  const map = useMap(); // Récupération de l'instance de la carte
  useEffect(() => {
    map.setView(positions); // Met à jour la vue de la carte
  }, [positions, map]);

  return null; // Ce composant ne rend rien
};

export default function Map({
  latitude = 14.71128,
  longitude = -17.4567,
  entrepriseName,
}) {
  const { data: locations = [], error, isLoading } = useLocationsQuery();

  const defaultPosition = [Number(latitude), Number(longitude)];
  const [positions, setPositions] = useState(defaultPosition);

  useEffect(() => {
    setPositions([Number(latitude), Number(longitude)]);
  }, [latitude, longitude]);

  const getZoom = (locations) => {
    if (!locations || locations.length === 0) return 15; // Valeur par défaut si locations est vide
    if (locations.length > 30) return 10;
    if (locations.length > 5) return 12;
    return 15;
  };

  const zoomLevel = getZoom(locations?.data);

  const customIcon = new L.Icon({
    iconUrl: "./Red-removebg-preview.png",
    iconSize: [30, 40],
    iconAnchor: [17, 45],
    popupAnchor: [0, -45],
  });

  if (isLoading) return (
    <div className="w-full h-[90vh] flex items-center justify-center">
      <ScaleLoader
        height={40}
        margin={3}
        radius={4}
        width={9}
        color={"#1D428A"}
      />
    </div>
  );
  if (error) return <div>Erreur de chargement des locations.</div>;
  return (
    <MapContainer
      center={positions}
      zoom={zoomLevel}
      style={{ height: "100vh", width: "100%" }}
    >
      <SetViewOnChange positions={positions} /> 
      <LayersControl>
        <LayersControl.BaseLayer checked name="Terrain - World Imagery">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='<a href="Tiles &copy; Esri">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="Jawg-Lagoon">
          <TileLayer
            url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=2U5baWI92SCFF5D9Gp53vRanR2r9g5TQ6X5qhEY4Z0tIQUijlbOEbW2eZmOLGfx9"
            attribution='<a href="https://jawg.io">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>

        {isLoading
          ? []
          : locations.data.map(
              ({ image, nom, numero, adresse, horaire }, index) => (
                <Marker
                  key={index}
                  position={[
                    adresse.latitude ?? 14.8928,
                    adresse.longitude ?? -17.4467,
                  ]}
                  icon={customIcon}
                >
                  <Popup>
                    <div className="max-w-[200px]">
                      <div className="m-0">
                        <img
                          className="rounded-t-lg w-[100%] h-[150px]"
                          src={`${apiImgUrl}/storage/${image}`}
                          alt=""
                        />
                      </div>
                      <div className="">
                        <div>
                          <h5 className="mb-2 text-2xl font-bold tracking-tight">
                            {nom}
                          </h5>
                        </div>
                        <h6 className="text-sm font-quicksand text-main-red font-semibold">
                          quartier:{" "}
                          <span className=" font-normal text-gray-700">
                            {numero}
                          </span>
                        </h6>
                        <h6 className="text-sm font-quicksand text-main-red font-semibold">
                          Adresse:{" "}
                          <span className=" font-normal text-gray-700">
                            {adresse.quartier}
                          </span>
                        </h6>
                        <h6 className="text-sm font-quicksand text-main-red font-semibold">
                          Horaires:{" "}
                          <span className=" font-normal text-gray-700">
                            {horaire}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              )
            )}

        <Marker
          position={[
            latitude ?? locations?.data[0].latitude,
            longitude ?? locations?.data[0].longitude,
          ]}
          icon={customIcon}
        >
          <Popup>
            {isLoading
              ? []
              : locations.data.map((location, index) => (
                  <div key={index} className="max-w-[200px]">
                    <div className="m-0">
                      <img
                        className="rounded-t-lg w-[100%] h-[100px]"
                        src={`${apiImgUrl}/storage/${location?.image}`}
                        alt=""
                      />
                    </div>
                    <div className="">
                      <div>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                          {location.nom}
                        </h5>
                      </div>
                      <h6 className="text-sm font-quicksand text-main-red font-semibold">
                        quartier:{" "}
                        <span className=" font-normal text-gray-700">
                          {location.numero}
                        </span>
                      </h6>
                      <h6 className="text-sm font-quicksand text-main-red font-semibold">
                        Adresse:{" "}
                        <span className=" font-normal text-gray-700">
                          {location.adresse.quartier}
                        </span>
                      </h6>
                      <h6 className="text-sm font-quicksand text-main-red font-semibold">
                        Horaires:{" "}
                        <span className=" font-normal text-gray-700">
                          {location.horaire}
                        </span>
                      </h6>
                    </div>
                  </div>
                ))}
          </Popup>
        </Marker>
      </LayersControl>
    </MapContainer>
  );
}
