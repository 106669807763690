import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const ButtonSubmit = ({ isLoading }) => {
  return (
    <div className="flex justify-end items-end">
      <button
        type="submit"
        //   className={"bg-main-blue rounded-lg p-4 py-2 text-white text-[13px] "}
        // >
        className={`bg-main-blue rounded-lg p-4 py-2 text-white text-[13px] ${
          isLoading
            ? "bg-main-blue opacity-85 cursor-not-allowed text-disabled text-white relative"
            : "bg-main-blue text-active text-white hover:bg-main-blue"
        } ${isLoading ? "relative" : ""}`}
      >
        {isLoading ? (
          <div className="flex">
          <span>Chargement...</span>
          <div className="border-4 border-solid border-blue-300 border-t-4 border-t-blue-800 rounded-full w-5 h-5  animate-spin ms-2"></div>
          </div>
        ) : (
          <>
            Réserve maintenant{" "}
            <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
          </>
        )}
      </button>
    </div>
  );
};
