import { useContext } from "react";
import { EntrepriseContext } from "../../contexte/contactEntreprise/EntrepriseContext";

const useEntreprise = () => {
  const useContextEntreprise = useContext(EntrepriseContext);

  return useContextEntreprise;
};

export default useEntreprise;
