import React, { useEffect, useState } from "react";

export const Modal = ({ showModal, closeModal, images, selectedImage }) => {
  const [currentIndex, setCurrentIndex] = useState(
    images.indexOf(selectedImage)
  );

  useEffect(() => {
    setCurrentIndex(images.indexOf(selectedImage));
  }, [selectedImage, images]);

  // Fonction pour aller à l'image suivante
  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Fonction pour aller à l'image précédente
  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  const selectImage = (index) => {
    setCurrentIndex(index);
  };

  return showModal ? (
    <div
      onClick={closeModal}
      className="fixed animate-slideUp ease-in-out delay-500 inset-0 z-50 flex justify-center items-center bg-main-black "
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute top-5 right-5 text-white"
      >
        <button onClick={closeModal} className="font-medium text-lg">
          X
        </button>
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className=" font-[Quicksand] w-[200px] rounded-lg  text-black  flex flex-col justify-center items-center"
      >
        {/* Boutons de navigation */}
        <button
          onClick={goToPrevious}
          className="absolute left-5 top-1/2 transform -translate-y-1/2 text-white font-medium text-2xl"
        >
          &#10094;
        </button>
        <button
          onClick={goToNext}
          className="absolute right-5 top-1/2 transform -translate-y-1/2 text-white font-medium text-2xl"
        >
          &#10095;
        </button>

        {/* Affichage de l'image actuelle */}
        <div className="flex justify-center items-center">
          <img
            src={images[currentIndex]}
            alt={`Image ${currentIndex + 1}`}
            className="max-w-full max-h-full object-contain"
          />
        </div>

        {/* <div className="text-center text-white mt-3">
          {currentIndex + 1} / {images.length}
        </div> */}
        <div className="flex gap-2 overflow-x-auto absolute bottom-3 pb-2">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => selectImage(index)}
              className={`w-20 h- object-cover cursor-pointer rounded ${
                currentIndex === index ? "border-2 border-blue-500" : "border"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};
