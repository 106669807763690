import React, { useState } from "react";
import NavMobile from "./navbar/NavMobile";
import NavWeb from "./navbar/NavWeb";

export default function NavBar() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  return (
    <nav className="bg-white px-0 mx-0 shadow-md fixed z-50 w-full">
      <NavWeb isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />
      <NavMobile isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />
    </nav>
  );
}
