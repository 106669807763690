import React from "react";
import Bouton from "./Bouton";
import { Link } from "react-router-dom";

export const DevenirPartenaireComponent = () => {
  return (
    <section className=" mx-auto flex max-sm:w-full px-5 flex-col items-center justify-center pb- md:w-[70%] ">
      <h1 className="text-h4 leading-h4 text-center font-arial font-bold text-black w-[100%] h-[30px] mt-8 md:mt-0 mb-[2.5%] max-lg:w-[80%] max-md:w-[100%] max-sm:text-h5 max-sm:leading-h5 ">
        Devenir un partenaire de Ket Ket
      </h1>
      <p className="text-center text-sm font-quicksand font-semibold leading-[28px]">
        Nous proposons un portail exclusif pour découvrir toutes les activités
        sportives de la capitale, facilitant ainsi la participation. En tant
        qu'experts du secteur, nous offrons des solutions sur mesure pour vos
        employés. Devenez partenaire et contribuez à enrichir la vie
        professionnelle tout en soutenant l'économie sénégalaise.
      </p>
      <Link
        to={"devenir-partenaire"}
        className=" mt-7 mx-auto flex items-center justify-center"
      >
        <Bouton
          label="Devenons partenaire"
          className=" bg-main-blue font-semibold py-2  hover:text-main-blue hover:bg-white hover:border-2 hover:border-[#1D428A] text-h6  font-r-mono text-white text-center text-sm transition-all duration-500 ease-in-out rounded-full "
        />
      </Link>
    </section>
  );
};
