import { useQuery } from "@tanstack/react-query";
import { fetchClients } from "./api";

// Hook pour récupérer les Clients
export const useClientsQuery = () => {
  return useQuery({
    queryKey: ["clients"],
    queryFn: fetchClients,
    // staleTime: 1000 * 60 * 10, 
    // cacheTime: 1000 * 60 * 30, 
    // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
  });
};
