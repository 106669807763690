import React from "react";
import { Link, NavLink } from "react-router-dom";
import NewsletterForm from "./Formulaires/NewsletterForm";
import CardFooter from "./footer/CardFooter";
import { followUse, navLinks, others } from "./footer/data";
import FollowUse from "./footer/FollowUse";
import Logo from "./footer/Logo";
import Contacts from "./footer/Contacts";

export const Footer = () => {
  return (
    <footer className="w-[90%] mx-auto pe-4 max-sm:w-full max-sm:px-5  py-6 lg:py-8">
      <div className="md:flex md:justify-between gap-10 w-full px-1 ">
        <div className="flex-grow">
          <Logo />
          <Contacts />
          <FollowUse datas={followUse} />
        </div>
        <CardFooter title={"Site"} datas={navLinks} />
        <CardFooter title={"other"} datas={others} />
        <NewsletterForm />
      </div>
      <div className="py-[0.5px] mt-4 bg-slate-700 sm:mx-auto w-full"></div>
    </footer>
  );
};
