import React from "react";
import useEntreprise from "../../utils/hooks/entreprise/useEntreprise";
import { FormCollaborateur } from "./FormCollaborateur";
import Form from "./Form";

const FormEntreprise = () => {
  const {
    isLoading,
    handleSubmit,
    formState,
    handleChange,
    setNumero,
    numero,
  } = useEntreprise();
  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        FormContainer={
          <FormCollaborateur
            formState={formState}
            handleChange={handleChange}
            setPhone={setNumero}
            phone={numero}
          />
        }
        isLoading={isLoading}
      />
    </div>
  );
};

export default FormEntreprise;
