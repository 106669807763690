import React from "react";
import InputLabel from "./InputLabel";
import { TextareaLabel } from "./TextareaLabel";
import InputPhone from "./InputPhone";
import useClient from "../../utils/hooks/clients/useClient";

export const FormSport = () => {
  const {
    numero,
    setNumero,
    numeroResponsable,
    setNumeroResponsable,
    formState,
    handleChange,
  } = useClient();

  return (
    <>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="prenom"
          value={formState.prenom}
          onChange={handleChange}
          children="Prenom"
        />
        <InputLabel
          type="text"
          name="nom_etablissement"
          value={formState.nom_etablissement}
          onChange={handleChange}
          children="Noms de votre entreprise"
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="nom"
          value={formState.nom}
          onChange={handleChange}
          children="Nom"
        />
        <InputLabel
          type="email"
          name="mail_responsable"
          children="E-mail du RH ou du Responsable "
          value={formState.mail_responsable}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="email"
          name="mail"
          value={formState.mail}
          onChange={handleChange}
          children="E-mail"
        />

        <InputPhone
          phone={numeroResponsable}
          setPhone={setNumeroResponsable}
          label={"Numéro de téléphone RH/Responsable"}
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <div className="mb-2 sm:flex flex-col w-full md:w-1/2 gap-3 ">
          <InputPhone
            phone={numero}
            setPhone={setNumero}
            label={"Numéro de téléphone"}
          />
        </div>
        <TextareaLabel
          label="Description de votre entreprise"
          placeholder="Message"
          value={formState.description_etablissement}
          onChange={handleChange}
          name="description_etablissement"
        />
      </div>
    </>
  );
};
