import React, { useState, useRef, act } from "react";
import CheckboxItem from "./small_components/checkbox_component";
import Voir_tag from "./small_components/modal_voir_compo";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export function ModalView({
  name,
  quartier,
  image_collection,
  location,
  fallbackImages,
  slidePicOne,
  slidePicTwo,
  description,
  address,
  horaire,
  categorie,
  activity,
  handleCloseViewModal,
}) {
  const formattedCategorie = categorie
    ? categorie.replace(/_/g, " ")
    : "Default Category"; // Replace with a default value or empty string

  const [hover, setHover] = useState(true);

  const [open, setOpen] = useState(false);
  const slides = [
    { src: slidePicOne },
    { src: slidePicTwo },
    { src: "../celux_five.jpg" },
  ];

  return (
    <>
      <div className="bg-white w-[100vw] overflow-y-scroll px-0 flex flex-col justify-center items-center">
        <div className="flex flex-col w-[80%] m-auto">
          <div className=" w-full h-[40px] mt-[2.25%]">
            <img
              role="button"
              tabIndex={0}
              onClick={handleCloseViewModal}
              src="../img/close_voirmodal.png"
              className="w-[42px] h-[42px] mt-[0px] rounded-[50%] ml-[92.35%]"
            ></img>
          </div>
          <div className="text-h5 font-arial  mt-[0.15%] mb-5 text-black">
            <strong>{name} - </strong> Dakar, {quartier}
          </div>

          <div className="tags w-full h-[60px]  mt-auto flex items-center gap-[15px]">
            <div className=" w-[126px] h-[35px] rounded-[5px] bg-main-red flex items-center justify-center ">
              <div className="text-center text-sm leading-[20px] font-semibold  text-white font-quicksand capitalize">
                {" "}
                {formattedCategorie}{" "}
              </div>
            </div>

            <div className="bg-main-blue w-[126px] h-[35px] rounded-[5px] flex items-center justify-center ">
              <div className="text-center text-sm leading-p font-semibold text-white align-middle font-quicksand capitalize">
                {" "}
                {activity}
              </div>
            </div>
          </div>
        </div>
        <div className=" w-[80%] h-[800px] flex justify-between max-md:flex-col">
          <div className="w-[60%] max-md:w-[100%] h-[100%]  flex flex-col">
            <div
              className="w-[100%] bg-black z-10 h-[450px] cursor-pointer flex rounded-[5px]"
              onMouseEnter={() => setHover(false)}
              onMouseLeave={() => setHover(true)}
              onClick={() => setOpen(true)}
              style={{
                backgroundImage: `url(${image_collection})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
                index={0}
                plugins={[Thumbnails]}
              />

              {hover ? null : (
                <div className=" animate-slideUp  flex h-[150px] z-10 w-[100%] inset-0 backdrop-blur-2xl bg-opacity-10 rounded-b-[5px] mt-auto bg-black pl-5 gap-5 items-center border ease-in-out delay-300 border-[#D4D4D4] border-opacity-30  ">
                  <div
                    className="bg-slate-300 w-[180px] h-[100px]"
                    style={{
                      backgroundImage: `url(${slidePicOne})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>

                  <div
                    className="bg-slate-300 w-[180px] h-[100px]"
                    style={{
                      backgroundImage: `url(${slidePicTwo})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>

                  <div
                    className="bg-slate-300 w-[180px] h-[100px]"
                    style={{
                      backgroundImage: "url(../img/celux_one.jpg)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>

                  <div className="bg-slate-300 w-[180px] h-[100px]"></div>
                </div>
              )}
            </div>

            <div className="text-p leading-p w-[89.05%] mt-[3%] font-quicksand  z-20">
              {description}
            </div>

            <div className="text-h5 leading-h5 font-arial mt-[2%]  font-bold text-black ">
              Sports et activités proposés
            </div>

            <div className="ml-auto w-[120%] max-md:mb-10 max-md:mt-6 max-md:w-[100%] h-[145px] bg-white">
              <div className="w-[100%] h-[100%] overflow-x-auto overflow-y-hidden scrollbar-hide mt-[21px] flex gap-[12px]">
                <Voir_tag title="Entraînements cardio" />

                <Voir_tag title="Entraînements cardio" />

                <Voir_tag title="Entraînements cardio" />

                <Voir_tag title="Entraînements cardio" />
              </div>
            </div>
          </div>
          <div className="w-[36%] max-md:w-[100%] h-[100%] flex flex-col">
            <div className="text-h4  ml-[5.7%] font-bold font-arial -mt-[7.25%] text-black ">
              Addresse
            </div>
            <div className=" mt-[1.5%] bg-main-blue rounded-[15px] ">
              <div className="text-h6 font-semibold font-quicksand leading-h6 mt-[5.5%] ml-[5%] text-white ">
                {address}
              </div>

              <div
                className="m-[5%]  rounded-[5px] h-[251px] "
                style={{
                  backgroundImage: "url(../img/celux_loc.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>

            <div className="text-h5  ml-[5.7%] font-bold font-arial mt-[2.5%] text-black ">
              Horaires
            </div>

            <div className=" h-[80px] bg-main-blue mt-[1%] rounded-[15px] flex items-center">
              <svg
                className="ml-[5%]"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 2.6665C14.249 2.6665 12.5152 3.01138 10.8975 3.68144C9.27984 4.35151 7.80998 5.33363 6.57187 6.57175C4.07138 9.07223 2.66663 12.4636 2.66663 15.9998C2.66663 19.5361 4.07138 22.9274 6.57187 25.4279C7.80998 26.666 9.27984 27.6482 10.8975 28.3182C12.5152 28.9883 14.249 29.3332 16 29.3332C19.5362 29.3332 22.9276 27.9284 25.428 25.4279C27.9285 22.9274 29.3333 19.5361 29.3333 15.9998C29.3333 14.2489 28.9884 12.5151 28.3184 10.8974C27.6483 9.27972 26.6662 7.80986 25.428 6.57175C24.1899 5.33363 22.7201 4.35151 21.1024 3.68144C19.4847 3.01138 17.7509 2.6665 16 2.6665ZM21.6 21.5998L14.6666 17.3332V9.33317H16.6666V16.2665L22.6666 19.8665L21.6 21.5998Z"
                  fill="white"
                />
              </svg>

              <div className="text-h5 leading-h5 font-arial ml-[1%] font-semibold text-white ">
                {horaire}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
