import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchLocations,
  addNewLocation,
  updateLocation,
  deleteLocation,
  fetchLocationById,
} from "./api"; 

// Hook pour récupérer les partenaires
export const useLocationsQuery = () => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};

// Hooks pour recuperer une partenaires
export const useLocationQuery = (slug) => {
  return useQuery({
    queryKey: ["location", slug],
    queryFn: () => fetchLocationById(slug),
    enabled: !!slug,
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};

// Hook pour ajouter une nouvelle location
export const useAddLocationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(addNewLocation, {
    onSuccess: () => {
      // Invalidate and refetch locations after a successful mutation
      queryClient.invalidateQueries(["locations"]);
    },
  });
};

// Hook pour mettre à jour une location
export const useUpdateLocationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(({ id, updatedData }) => updateLocation(id, updatedData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
    },
  });
};

// Hook pour supprimer une location
export const useDeleteLocationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteLocation(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
    },
  });
};
