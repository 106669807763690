import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Logo from "./Logo";

const NavbarHeader = ({ setIsNavbarOpen }) => (
  <div className="flex justify-between items-center px-5 py-2">
    <Logo />
    <FontAwesomeIcon
      onClick={() => setIsNavbarOpen(false)}
      icon={faTimes}
      className="cursor-pointer md:hidden block text-3xl"
    />
  </div>
);

export default NavbarHeader;
