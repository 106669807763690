import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import useContacts from "../../hooks/recherche/useContacts";
import { useAddContactMutation } from "../../../api/contactpartenaire/hooks";

export const ClientContext = createContext();

const ClientContextProvider = ({ children }) => {
  const { setShowModalTwo, dateFormatte } = useContacts();
  const [numero, setNumero] = useState("");
  const [numeroResponsable, setNumeroResponsable] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    adresse_id: 1,
    date_creation: dateFormatte,
    date_modification: dateFormatte,
    description_etablissement: "",
    mail: "",
    mail_responsable: "",
    nom: "",
    nom_etablissement: "",
    numero: "",
    numero_responsable: "",
    prenom: "",
    type: "",
  });

  const validateForm = () => {
    if (
      !formState.nom ||
      !formState.mail ||
      !numero ||
      !formState.prenom ||
      !numeroResponsable ||
      !formState.nom_etablissement ||
      !formState.mail_responsable ||
      !formState.description_etablissement
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formState.mail)) {
      toast.error("L'adresse e-mail est invalide.");
      return false;
    }
    if (!emailRegex.test(formState.mail_responsable)) {
      toast.error("L'adresse e-mail du responsable est invalide.");
      return false;
    }
    return true;
  };

  const { mutateAsync: addNewContact } = useAddContactMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const resetForm = () => {
    setShowModalTwo(false);
    setFormState({
      adresse_id: 1,
      date_creation: dateFormatte,
      date_modification: dateFormatte,
      description_etablissement: "",
      mail: "",
      mail_responsable: "",
      nom: "",
      nom_etablissement: "",
      numero: "",
      numero_responsable: "",
      prenom: "",
      type: "",
    });
    setNumero("");
    setNumeroResponsable("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const payload = {
      ...formState,
      numero,
      numero_responsable: numeroResponsable,
      date_creation: dateFormatte,
      date_modification: dateFormatte,
      type: "Salarié",
    };

    try {
      setIsLoading(true);
      const response = await addNewContact(payload);
      if (response.data) {
        resetForm();
        toast.success("Contact ajouté avec succès !");
      } else {
        toast.error("Une erreur s'est produite.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur inattendue est survenue.");
    } finally {
      setIsLoading(false);
    }
  };
  const value = {
    numero,
    setNumero,
    numeroResponsable,
    setNumeroResponsable,
    isLoading,
    setIsLoading,
    formState,
    setFormState,
    handleChange,
    handleSubmit,
  };
  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export default ClientContextProvider;
