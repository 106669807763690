// src/components/Bouton.js
import React from "react";

const Bouton = ({ onClick, icon, label, className }) => {
  return (
    <button
      onClick={onClick}
      className={`relative ${className} rounded-lg flex items-center px-4 gap-2 text-sm`}
    >
      {icon}
      {label}
    </button>
  );
};

export default Bouton;
