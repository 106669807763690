import React from "react";
import { Link } from "react-router-dom";
import Logo from "../components/navbar/Logo";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center">
      <h1 className="text-6xl font-bold text-main-red font-quicksand">Oops!</h1>
      <p className="text-xl  mb-3 font-bold uppercase font-quicksand">
        {" "}
        <span>404</span>-page non trouvée!{" "}
      </p>
      <p className="text-sm text-center font-quicksand w-1/3 mb-3">
        La page que vous recherchez a peut-être été supprimée, son nom est
        changé, ou elle est temporairement indisponible.
      </p>
      <Link
        to="/"
        className=" items-center hidden md:flex py-1 px-4 text-sm font-medium gap-2 bg-main-blue border-2 border-[#1D428A] text-white rounded-full hover:bg-white hover:text-main-blue" 
      >
        Retourner à l'accueil
      </Link>
    </div>
  );
};

export default NotFound;
