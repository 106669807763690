import React from "react";
import { ScaleLoader } from "react-spinners";

const Loader = () => (
  <div className="w-full h-[10rem] flex items-center justify-center">
    <ScaleLoader
      height={20}
      margin={2}
      radius={4}
      width={8}
      color={"#1D428A"}
    />
  </div>
);
export default Loader;
