import React from "react";
import { NavbarLinkMobile } from "./NavbarLink";
import NavbarActions from "./NavbarActions";
import NavbarHeader from "./NavbarHeader";

const NavMobile = ({ isNavbarOpen, setIsNavbarOpen }) => {
  return (
    <div
      className={`
        md:hidden bg-white fixed w-full  text-2xl h-full top-0 overflow-y-auto bottom-0 pt-2 shadow-sm opacity- pl-
        duration-500 ${isNavbarOpen ? "left-0" : "left-[-100%]"}
        `}
    >
      <NavbarHeader setIsNavbarOpen={setIsNavbarOpen} />
      <div className="flex flex-col items-start py-6 gap-5  justify-start">
        <NavbarLinkMobile />
        <NavbarActions />
      </div> 
    </div>
  );
};

export default NavMobile;
