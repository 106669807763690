import React from "react"; // Import React
import NavBar from "./components/navbar";
import Homepage from "./pages/Homepage";
import ContainerComponent from "./components/ContainerComponent";

export default function App() {
  return (
    <ContainerComponent>
      <Homepage/>
    </ContainerComponent>
  );
}
