import {
  faArrowTrendUp,
  faChartSimple,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const EtrePartenaire = () => {
  return (
    <div className=" flex-col pt-3 hidden md:flex gap-[10%] mx-auto w-[90%]  pe-4 max-sm:w-full max-sm:px-5 max-md:mb-[5%] ">
      {/*  */}
      <div className="mt-8 max-md:mt-0 service-container ">
        <h4 className="text-h4 leading-h4 text-start ml-[100px] max-md:ml-0 font-Quicksand md:pb-11 font-bold text-main-blue w-[100%] h-[30px] mb-[4%] max-lg:w-[80%] max-sm:text-h5 max-sm:leading-h5">
          Pourquoi être partenaire avec Ket Ket ?
        </h4>
        {/* <div className="flex justify-between items-center pt-8 pb-12">
        {LOGOS.map((logo, index) => (
          <div className=" w-[130px]">
            <img
              className="  object-cover "
              key={index}
              src={logo.src}
              alt={`Logo ${index + 1}`}
            />
          </div>
        ))}
      </div> */}

        <div
          className="service-main-container w-[100vw] max-md:w-[100%] flex justify-center max-lg:content-center "
          // style={{
          //   backgroundSize: "400px",
          //   backgroundRepeat: "no-repeat",
          //   // backgroundPosition: "right",
          // }}
        >
          <section className="hidescrollbar w-[100%] max-md:w-[100%]">
            <div className="red-circleRight max-lg:hidden mt-[-1%] h-[0.35%] z-10 max-md:mt-[-60%]">
              <img
                className="w-[150px] h-[px] ml-[3%] max-md:ml-[-10%]"
                src="../img/red-circle.png"
                alt=""
              />
            </div>
            <div className="service-main-content mt-[5%] relative w-[50%] max-md:mb-[170px]  max-md:w-[100%] max-lg:mx-auto max-2xl:gap-[41px] flex max-lg:flex-col gap-[41px] z-30 max-lg:w-[350px] max-md:mx-auto ml-[8%]">
              <div className="relative w-[45%]  h-[310px] overflow-hidden max-md:w-full max-md:mb-[100px] text-medium flex-shrink-0 bg-white z-50 rounded-[12px] shadow-service-shade flex flex-col">
                <div className="card-top bg-sample-class relative h-[50px] rounded-t-[12px] z-30">
                  <div className="py-[6px] bg-black"></div>
                  <div className="flex">
                    <div className="rounded-full h-[40px] w-[40px] relative z-40 bg-black text-white ml-[5%] my-[2%] flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faRocket}
                        className="feather feather-heart simple-icon-class w-[25px] h-[25px]"
                      />
                    </div>
                    <div className=" w-[80%] h-[2px] bg-black text-white my-[10%] flex items-center justify-center"></div>
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] relative z-50">
                  <h6 className="text-black text-[16px] ml-[5.15%] w-[78%] pt-[7.6%] font-bold">
                    Amélioration du bien-être en entreprise
                  </h6>
                  <p className="font-arial text-[11px] ml-[5.15%] w-[93%] leading-[25px] mb-2">
                    En devenant partenaire de Ket Ket, vous contribuez à
                    améliorer le bien-être de vos employés. Cette collaboration
                    permet d'offrir des solutions innovantes pour favoriser la
                    santé et le dynamisme de votre équipe, renforçant ainsi la
                    productivité et la satisfaction au travail.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-main-content max-md:w-[100%] max-md:mb-[160px] relative w-[50%] max-lg:mx-auto max-2xl:gap-[41px] flex max-lg:flex-col gap-[41px] z-30 max-lg:w-[350px] -mt-[245px] xl:mt-[-220] ml-[34%]">
              <div className="relative w-[45%] max-md:w-full  h-[310px] overflow-hidden max-md:mb-[115px] text-medium flex-shrink-0 bg-white z-50 rounded-[12px] shadow-service-shade flex flex-col">
                <div className="card-top bg-sample-class relative h-[50px] rounded-t-[12px] z-30">
                  <div className="py-[6px] bg-[#C8102E]"></div>
                  <div className="flex">
                    <div className="rounded-full h-[40px] w-[40px] relative z-40 bg-[#C8102E] text-white ml-[5%] my-[2%] flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faArrowTrendUp}
                        className="feather feather-heart simple-icon-class w-[25px] h-[25px]"
                      />
                    </div>
                    <div className=" w-[80%] h-[2px] bg-[#C8102E] text-white my-[10%] flex items-center justify-center"></div>
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] relative z-50">
                  <h6 className="text-black text-[16px] ml-[5.15%] w-[78%] pt-[7.6%] font-bold">
                    Investissement dans la performance et la durabilité
                  </h6>
                  <p className="font-arial text-[11px] ml-[5.15%] w-[93%] leading-[25px] mb-2">
                    Collaborer avec Ket Ket est un investissement stratégique
                    pour la performance durable de votre entreprise. En
                    intégrant des programmes de sport et de bien-être, vous
                    stimulez l'engagement des employés, réduisez les coûts liés
                    à l'absentéisme et améliorez la satisfaction client,
                    renforçant ainsi votre position sur le marché.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-main-content max-md:w-[100%] max-md:mb-[20px] relative w-[50%] max-lg:mx-auto max-2xl:gap-[41px] flex max-lg:flex-col gap-[41px] z-30 max-lg:w-[350px] -mt-[250px] ml-[60%]">
              <div className="relative w-[45%]  h-[310px] overflow-hidden max-md:w-full  text-medium flex-shrink-0 bg-white z-50 rounded-[12px] shadow-service-shade flex flex-col">
                <div className="card-top bg-sample-class relative h-[50px] rounded-t-[12px] z-30">
                  <div className="py-[6px] bg-[#1D428A]"></div>
                  <div className="flex">
                    <div className="rounded-full h-[40px] w-[40px] relative z-40 bg-[#1D428A] text-white ml-[5%] my-[2%] flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        className="feather feather-heart simple-icon-class w-[25px] h-[25px]"
                      />
                    </div>
                    <div className=" w-[80%] h-[2px] bg-[#1D428A] text-white my-[10%] flex items-center justify-center"></div>
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] relative z-50">
                  <h6 className="text-black text-[16px] ml-[5.15%] w-[78%] pt-[7.6%] font-bold">
                    Expansion dans le secteur sportif au Sénégal
                  </h6>
                  <p className="font-arial text-[11px] ml-[5.15%] w-[93%] leading-[25px] mb-2">
                    En partenariat avec Ket Ket, vous pouvez étendre votre
                    présence et renforcer votre impact dans le domaine du sport
                    au Sénégal. Ensemble, nous facilitons l'accès à des
                    infrastructures sportives de qualité et proposons des
                    solutions d'adhésion attractives aux entreprises, promouvant
                    un mode de vie sain et actif pour les salariés.
                  </p>
                </div>
              </div>
            </div>
            <div className="red-circleRight max-lg:hidden h-[0.35%] z-10 max-md:hidden">
              <img
                className="w-[150px] mt-[-7%] h-[px] ml-[76%] "
                src="../img/red-circle.png"
                alt=""
              />
            </div>
          </section>
        </div>
      </div>
      {/*  */}
      <div className="squibbly-container w-[180px] h-[100%] max-lg:hidden">
        <div className="squib ml-[1%] mt-[-440%] ">
          <img className="w-[38%]" src="../img/blue-vector.png" alt="" />
        </div>
      </div>
    </div>
  );
};
