import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "./navbar";
// import Logos from "./partenaire_logos";
import { Footer } from "./Footer";
import { DevenirPartenaireComponent } from "./DevenirPartenaireComponent";
import { CardService } from "./CardService";
import { RechercheSection } from "./RechercheSection";
import { EtrePartenaire } from "./EtrePartenaire";
import LeftSection from "./partenaires/LeftSection";
import RightSection from "./partenaires/RightSection";
import Apropos from "./partenaires/Apropos";
import ListeEntreprises from "./partenaires/ListeEntreprises";
import { usePartenairesQuery } from "../api/partenaires/hooks";
import { useClientsQuery } from "../api/clients/hooks";
import Logos from "./partenaire_logos";
// import Logos from "./partenaires/Logos";

export default function Hero() {
  const navigate = useNavigate();

  const [articleWithId1, setArticleWithId1] = useState(null);
  const [awards, setAwards] = useState(null);

  useEffect(() => {
    Promise.all([
      fetch("https://www.api.ketket.fewnu.app/api/articles", {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache", // Prevent caching
        },
      })
        .then((response) => response.json())
        .then((data) => data.data || []),

      fetch("https://www.api.ketket.fewnu.app/api/accolands", {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache", // Prevent caching
        },
      })
        .then((response) => response.json())
        .then((data) => data.data || []),
    ])
      .then(([articles, awardsResponse]) => {
        const newestArticle =
          articles.length > 0
            ? articles.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )[0]
            : null;
        setArticleWithId1(newestArticle);

        const newestAwards =
          awardsResponse.length > 0
            ? awardsResponse.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )[0]
            : null;
        setAwards(newestAwards);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setArticleWithId1(null);
        setAwards(null);
      });
  }, []);

  const baseUrl = "http://api.ketket.fewnu.app/storage/";
  // const imageUrl = articleWithId1 ? `${baseUrl}${articleWithId1.image}` : "";

  // const handlePartnerClick = () => {
  //   navigate("/devenirPartenaire"); // Redirection to the partner page
  // };

  return (
    <>
      <NavBar />
      <section className="pt-10 max-md:pt-0">
        <div className="hero-content-container w-[90%] mx-auto grid grid-cols-2 max-lg:grid-cols-1 gap-[30px] py-4 pt-12">
          <LeftSection />
          <RightSection />
        </div>
        <AboutComponent />
      </section>
    </>
  );
}

export function AboutComponent() {
  const [about, setAbout] = useState(null);

  useEffect(() => {
    // Fetch data from Laravel API
    fetch("https://www.api.ketket.fewnu.app/api/abouts", {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          const newestAbout = data.data[0] || null;
          setAbout(newestAbout);
        } else {
          console.warn("No valid data found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const aboutImage1 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image1}`
    : "";
  const aboutImage2 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image2}`
    : "";
  const aboutImage3 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image3}`
    : "";
  const aboutImage4 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image4}`
    : "";
  const aboutImage5 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image5}`
    : "";

  const LOGOS = [
    { src: "/img/parter/senelec.png" },
    { src: "/img/parter/baobab.png" },
    { src: "/img/parter/elton.png" },
    { src: "/img/parter/wave.png" },
    { src: "/img/parter/prem.png" },
    { src: "/img/parter/senelec.png" },
    { src: "/img/parter/baobab.png" },
    { src: "/img/parter/elton.png" },
    // { src: "/img/parter/wave.png" },
  ];
  const { data: partenaires = [], error, isLoading } = usePartenairesQuery();
  const { data: clients = [], loading } = useClientsQuery();
  // const clientsData = clients?.data;
  const clientsData = clients?.data?.map((client) => client.logo) || [];
  const partenairesData =
    partenaires?.data?.map((partenaire) => partenaire.logo) || [];
  console.log(partenairesData);

  return (
    <>
      <Apropos
        about={about}
        aboutImage1={aboutImage1}
        aboutImage2={aboutImage2}
        aboutImage3={aboutImage3}
        aboutImage5={aboutImage5}
      />
      <ListeEntreprises isLoading={loading} clientsData={clients?.data} />
      {/* <ListeEntreprises isLoading={loading} clientsData={clientsData} /> */}
      {/* <Logos
        datas={partenaires?.data}
        isLoading={isLoading}
        label={"Ils nous font confiance"}
      /> */}
      <EtrePartenaire />
      <DevenirPartenaireComponent />
      <ListeEntreprises isLoading={isLoading} clientsData={partenaires?.data} />
      <CardService />
      <RechercheSection />
      <Footer />
    </>
  );
}
