import { fetchApi } from "../fetchApi";

// Appel GET pour récupérer les contact-partenaires
export const fetchContacts = () => fetchApi("/contact-partenaires/");

// Appel POST pour ajouter un nouvel item (par exemple un nouveau partenaire)
export const addNewContact = (datas) =>
  fetchApi("/contact-partenaires/", "POST", datas);

// Appel PUT pour mettre à jour un item existant
export const updateContact = (id, updatedData) =>
  fetchApi(`/contact-partenaires/${id}`, "PUT", updatedData);

// Appel DELETE pour supprimer un item
export const deleteContact = (id) =>
  fetchApi(`/contact-partenaires/${id}`, "DELETE");
