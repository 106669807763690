import { useQuery } from "@tanstack/react-query";
import { fetchActivites } from "./api";

// Hook pour récupérer les Aativities
export const useActivitesQuery = () => {
  return useQuery({
    queryKey: ["activites"],
    queryFn: fetchActivites,
    // staleTime: 1000 * 60 * 10,
    // cacheTime: 1000 * 60 * 30,
    // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
  });
};
