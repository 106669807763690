import React, { useEffect, useState } from "react";
import CardAdvantages from "./homePage/CardAdvantages";

const advantages = [
  {
    titre: "Installations ultramodernes",
    description:
      "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna.",
    numero: 1,
  },
  {
    titre: "Programme d'Entraînement Personnalisé",
    description:
      "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna.",
    numero: 2,
  },
  {
    titre: "Communauté de Soutien",
    description:
      "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna.",
    numero: 3,
  },
  {
    titre: "Installations de Pointe",
    description:
      "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna.",
    numero: 4,
  },
  {
    titre: "Lorem ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna.",
    numero: 5,
  },
  {
    titre: "Lorem ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna.",
    numero: 6,
  },
];

export default function Advantage() {
  const [advantage, setAdvantage] = useState(null);
  const [stat, setStat] = useState(null);

  useEffect(() => {
    // Fetch data from Laravel API
    fetch("https://www.api.ketket.fewnu.app/api/advantages", {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the response contains valid data
        if (data.data && Array.isArray(data.data)) {
          const newestAdvantage = data.data[0] || null; // Take the first item if available
          setAdvantage(newestAdvantage);
        } else {
          console.warn("No valid data found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch data from Laravel API
    fetch("https://www.api.ketket.fewnu.app/api/statistics", {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the response contains valid data
        if (data.data && Array.isArray(data.data)) {
          const newestStat = data.data[0] || null; // Take the first item if available
          setStat(newestStat);
        } else {
          console.warn("No valid data found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const hideScrollBarStyle = {
    msOverflowStyle: "none", // for Internet Explorer and Edge
    scrollbarWidth: "none", // for Firefox
  };

  const hideScrollBarWebkit = {
    // for Webkit browsers (Chrome, Safari)
    overflow: "hidden",
  };

  return (
    <section>
      <div className="advantage-title relative z-2 w-[100%] flex place-content-evenly max-lg:mt-[30%] max-md:mt-[0%] ">
        <div
          className="relative w-[100%] max-md:mx-auto bg-dots-advantage bg-center bg-no-repeat flex flex-col pb-4"
          style={{ backgroundSize: "1300px" }}
        >
          <h4 className="text-h4 leading-h4 text-center font-arial font-bold text-black w-[100%] h-[30px] mb-[1.5%] max-md:mb-[5%] max-lg:w-[80%] max-md:w-[100%] max-sm:text-h5 max-sm:leading-h5 ">
            {advantage?.titre ? advantage.titre : "Nos atouts, notre avantage"}
          </h4>
          <CardAdvantages advantages={advantages} />
        </div>
      </div>

      <div className="advantage-blocks flex w-[100%] max-sm:mb-[50%] max-lg:mb-[20%] my-[100px] max-md:mt-[0%] justify-between">
        <div className="mx-auto w-[100%] flex flex-grow-0 place-content-evenly max-ml:w-[90%] max-lg:h-[35vh] max-lg:flex-col max-lg:gap-[30px] max-[400px]:mt-[5%] max-[420px]:mt-[10%] max-md:mt-[0%]">
          <div className="">
            <div className="text-[80px] font-[700] text-center font-arial text-black">
              {" "}
              {stat?.clients_satisfaits ? stat.clients_satisfaits : "750"}+
            </div>
            <div className="text-[20px] text-center -mt-[2%] leading-h4 font-arial font-bold">
              {stat?.additional_column1
                ? stat.additional_column1
                : "Client satisfaits"}
            </div>
          </div>
          <div className="">
            <div className="text-[80px] font-[700] text-center font-arial text-black">
              {" "}
              {stat?.avis_rares ? stat.avis_rares : "26"}+
            </div>
            <div className="text-[20px] text-center -mt-[2%]  leading-h4 font-arial font-bold">
              {stat?.additional_column2 ? stat.additional_column2 : "Activités"}
            </div>
          </div>
          <div className="  ">
            <div className="text-[80px] font-[700] text-center font-arial text-black">
              {" "}
              {stat?.sports_offerts ? stat.sports_offerts : "8"}+
            </div>
            <div className="text-[20px] text-center -mt-[2%]  leading-h4 font-arial font-bold">
              {stat?.additional_column3
                ? stat.additional_column3
                : "Enterprises clients"}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
