export const fitnessOptions = [
  { id: "salle", label: "Salle de sport" },
  { id: "club", label: "Club" },
  { id: "cours", label: "Cours" },
];

export const sportOptions = [
  { id: "football", label: "Football" },
  { id: "natation", label: "Natation" },
  { id: "badminton", label: "Badminton" },
  { id: "yoga", label: "Yoga" },
  { id: "karate", label: "Karaté" },
  { id: "boxe", label: "Boxe" },
  { id: "mma", label: "MMA" },
  { id: "gym", label: "Gym" },
  { id: "thai", label: "Muay Thai" },
  { id: "musculation", label: "Musculation" },
  { id: "jjb", label: "Jiujitsu 🇧🇷" },
  { id: "fitness", label: "Fitness" },
];
