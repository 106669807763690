import React, { useEffect, useState, useRef } from "react";

// import fallbackImage1 from '../../public/img/service1.png'; // Local fallback image

export default function Service() {
  const scrollContainerRef = useRef(null);

  // Function to scroll to the end based on screen width
  const scrollToEnd = () => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.clientWidth;
      let scrollPosition = 0;

      if (window.innerWidth < 1441) {
        if (containerWidth <= 930) {
          scrollPosition = 829;
        } else {
          scrollPosition = scrollContainerRef.current.scrollWidth;
        }
      } else {
        scrollPosition = 430;
      }

      scrollContainerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  // Function to scroll to the start
  const scrollToStart = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  };
  const [services, setServices] = useState([]);
  const [useStaticImage, setUseStaticImage] = useState(false);

  useEffect(() => {
    // Define a timeout promise
    const timeoutPromise = new Promise(
      (_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), 5000) // 5 seconds timeout
    );

    // Fetch data from API
    const fetchPromise = fetch(
      "https://www.api.ketket.fewnu.app/api/services",
      {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    ).then((response) => response.json());

    // Race between fetch and timeout
    Promise.race([fetchPromise, timeoutPromise])
      .then((data) => {
        // if (data.data && Array.isArray(data.data)) {
        //   const latestServices = data.data.slice(-4);
        //   setServices(latestServices);
        //   setUseStaticImage(false); // Use server images if available
        // } else {
        console.warn("No valid data found in response", data);
        setServices(getFallbackData());
        setUseStaticImage(true); // Use static images
        // }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setServices(getFallbackData());
        setUseStaticImage(true); // Use static images if there's an error
      });
  }, []);

  const fallbackImages = [
    "/img/service/service1.png",
    "/img/service/service2.png",
    "/img/service/service4.png",
    "/img/service/service3.png",
  ];

  const getFallbackData = () => [
    {
      image: fallbackImages[0], // Use image path directly
      subtitle: "Accès au sport pour tous:",
      description:
        "Ket Ket s'engage à faciliter l'accès des salariés à des structures sportives amateurs de qualité. En rendant le sport plus accessible, l'entreprise encourage une pratique régulière et contribue ainsi à améliorer la santé physique et mentale des employés.",
    },
    {
      image: fallbackImages[1], // Use image path directly
      subtitle: "Cohésion d'équipe renforcée",
      description:
        "La pratique sportive en entreprise peut être un excellent moyen de renforcer la cohésion d'équipe. Ket Ket propose des solutions d'adhésion qui permettent aux salariés de partager des moments conviviaux et de développer des liens solides en dehors du cadre professionnel.",
    },
    {
      image: fallbackImages[2], // Use image path directly
      subtitle: "Promotion d'un mode de vie actif",
      description:
        "Ket Ket encourage les entreprises à soutenir et à financer la pratique sportive de leurs employés. En mettant l'accent sur un mode de vie actif, l'entreprise vise à réduire le stress, favoriser le bien-être mental et renforcer le lien social au sein de l'entreprise.",
    },
    {
      image: fallbackImages[3], // Use image path directly
      subtitle: "Promotion de la diversité et de l'inclusion",
      description:
        "En rendant le sport accessible à tous les employés, Ket Ket favorise la diversité et l'inclusion, permettant à chacun de s'épanouir dans un environnement inclusif.",
      // description:
      //   "En rendant le sport accessible à tous les employés, quel que soit leur niveau ou leur expérience, Ket Ket encourage la diversité et l'inclusion en milieu professionnel. Chaque employé a la possibilité de participer et de s'épanouir dans un environnement sportif inclusif.",
    },
  ];

  const bgClasses = [
    "bg-main-blue",
    "bg-service-2",
    "bg-service-3",
    "bg-main-red",
  ];

  const bgClassesVertical = [
    "bg-main-blue",
    "bg-service-2",
    "bg-service-3",
    "bg-main-red",
  ];

  const getBgClass = (index) => {
    return bgClasses[index % bgClasses.length];
  };

  const getBgClassVertical = (index) => {
    return bgClassesVertical[index % bgClassesVertical.length];
  };

  return (
    <>
      <div className="service-container">
        <div className="service-title relative z-40 h-[50.8%]  w-[100%] bottom-[20%]  flex place-content-evenly ">
          <div className="squibbly-containr w-[24%] h-[100%] max-lg:hidden">
            <div className="squib ml-[23%] mt-[12.5%] max-ml:mt-[12.5%]">
              <img
                className="w-[36%] rotate-[0deg]"
                src="../img/blue-vector.png"
                alt=""
              />
            </div>
          </div>
          <div className="relative w-[51%] m-auto  grid place-items-center  max-lg:w-[86%] max-sm:w-[100%]">
            <div className="text-center w-[90%] mt-[4.3%] font-arial font-bold text-h3 max-ml:w-[100%] max-sm:w-[95%] leading-h3 text-main-black max-ml:text-h4 max-ml:leading-h4  max-sm:text-h5 max-sm:leading-h5">
              {services[0]?.title
                ? services[0]?.title
                : `Transformez votre entreprise avec les solutions sportives de Ket Ket`}
            </div>
          </div>
          <div className="squibbly-containr w-[24%] h-[100%] max-lg:hidden">
            <div className="squib ml-[45%] mt-[12.5%] max-ml:mt-[12.5%]">
              <img
                className="w-[45%] -rotate-[30deg]"
                src="../img/blue-vector.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="service-main-container w-[90vw] flex justify-center items-center mx-auto ">
          <section
            className="hidescrollbar pe-4 pb-5"
            ref={scrollContainerRef}
          >
          
            <div className="service-main-content py-8 relative mx-auto  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5  z-30">
              {services.map((service, index) => {
                const width = window.innerWidth;

                const bgClass =
                  width < 1025 ? getBgClassVertical(index) : getBgClass(index);

                return (
                  <div
                    key={index}
                    className="relative w-full text-medium flex-shrink-0 bg-white z-50 rounded-[12px]  shadow-service-shade flex flex-col "
                  >
                    <div
                      className={`card-top ${bgClass} relative h-[50px] rounded-t-[12px] z-30`}
                    >
                      <div className="rounded-full h-[64px] w-[64px] relative z-40  bg-white ml-[73.5%] mt-[5.1%] flex items-center justify-center">
                        <img
                          src={
                            useStaticImage
                              ? fallbackImages[index % fallbackImages.length]
                              : `https://www.api.ketket.fewnu.app/storage/${service.image}`
                          }
                          alt={`Image for ${service.subtitle}`}
                          className="w-[32px] h-[32px]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-[10px] relative z-50">
                      <h6 className=" text-[#ca1935] text-[13px]  ml-[5.15%] w-[78%] pt-[7.6%] font-bold">
                        {service.subtitle}
                      </h6>
                      <p className=" font-arial text-[11px] ml-[5.15%] w-[93%] -leading-[25px] mb-2">
                        {service.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="red-circleRight   z-10">
              <img
                className="w-[150px] h-[150px] -mt-[10%] -ml-[4%] "
                src="../img/red-circle.png"
                alt=""
              />
            </div> */}
          </section>
        </div>

        {/* <div className="relative w-[80vw] flex mx-auto justify-end z-50 max-lg:hidden">
          <div className="w-[85px] -mt-[28%] max-[1780px]:-mt-[30%] max-[1650px]:-mt-[34%] max-2xl:-mt-[36%] max-ml:-mt-[39%] max-xl:-mt-[45%] max-[1100px]:-mt-[49%]  h-[45px] bg-main-blue rounded-[20px] flex gap-[5px] items-center">
            <button
              onClick={scrollToStart}
              className="w-[38px] h-[38px] ml-[2px] bg-white rounded-full flex justify-center items-center"
            >
              <svg
                width="16"
                height="32"
                viewBox="0 0 16 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.45774 15.0525L10.0004 7.5098L11.8857 9.39513L5.28574 15.9951L11.8857 22.5951L10.0004 24.4805L2.45774 16.9378C2.20778 16.6878 2.06736 16.3487 2.06736 15.9951C2.06736 15.6416 2.20778 15.3025 2.45774 15.0525Z"
                  fill="#1D428A"
                />
              </svg>
            </button>

            <button
              onClick={scrollToEnd}
              className="w-[38px] h-[38px] bg-white rounded-full flex justify-center items-center cursor-pointer hover:animate-pulse ease-in-out duration-300"
            >
              <svg
                width="16"
                height="32"
                viewBox="0 0 16 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.5423 16.9475L5.99959 24.4902L4.11426 22.6049L10.7143 16.0049L4.11426 9.40486L5.99959 7.51953L13.5423 15.0622C13.7922 15.3122 13.9326 15.6513 13.9326 16.0049C13.9326 16.3584 13.7922 16.6975 13.5423 16.9475Z"
                  fill="#1D428A"
                />
              </svg>
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}
