import React, { createContext, useRef, useState } from "react";

export const RechercheContext = createContext();

const RechercheContextProvider = ({ children }) => {
  const [checkboxState, setCheckboxState] = useState({
    salleDeSport: false,
    football: false,
  });

  const [checkboxState_Activity, setCheckboxState_Activity] = useState({
    salleDeSport: false,
    football: false,
  });

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState(0);
  const [showViewModal, setShowViewModal] = useState(false);
  const searchModalRef = useRef(null);
  const viewModalRef = useRef(null);

  const onCheckboxChange_Activity = (id) => {
    setCheckboxState_Activity((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const onCheckboxChange = (id) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleCheckboxState = (id) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleOpenSearchModal = () => setShowSearchModal(true);
  const handleCloseSearchModal = () => setShowSearchModal(false);

  const value = {
    checkboxState,
    showSearchModal,
    checkboxState_Activity,
    searchModalRef,
    viewModalRef,
    selectedEnterprise,
    showViewModal,
    setCheckboxState,
    setShowSearchModal,
    setCheckboxState_Activity,
    setSelectedEnterprise,
    setShowViewModal,
    onCheckboxChange_Activity,
    onCheckboxChange,
    toggleCheckboxState,
    handleOpenSearchModal,
    handleCloseSearchModal,
  };

  return (
    <RechercheContext.Provider value={value}>
      {children}
    </RechercheContext.Provider>
  );
};

export default RechercheContextProvider;
