import React, { useEffect } from "react";
import { fitnessOptions, sportOptions } from "./small_components/datas";
import { CheckboxGroup } from "./small_components/CheckboxGroup";

export function ModalSearch({ checkboxState, setCheckboxState, onCheckboxChange }) {
  
  // const resetFilters = () => {
  //   const resetState = {};
  //   fitnessOptions.forEach((option) => {
  //     resetState[option.value] = false;
  //   });
  //   sportOptions.forEach((option) => {
  //     resetState[option.value] = false;
  //   });

  //   onCheckboxChange(resetState);
  //   console.log(checkboxState);
    
  // };
  const resetFilters = () => {
    const resetState = {};
    [...fitnessOptions, ...sportOptions].forEach(option => {
      resetState[option.value] = false;
    });
    setCheckboxState(resetState);
  };

  

  return (
    <div className="bg-white w-[450px] mx-auto py-4 rounded-[5px] overflow-hidden">
      <div className="flex flex-col px-4">
        <h5 className="text-[12px] font-bold text-black font-quicksand text-center mb-4">
          Filtrer par options
        </h5>

        <form className="flex flex-col bg-black-400 m-auto">
          <CheckboxGroup
            title="Options de fitness et bien-être"
            checkboxes={fitnessOptions}
            checkboxState={checkboxState}
            onCheckboxChange={onCheckboxChange}
          />

          <div className="border-[1px] border-[#828282] border-dashed my-3"></div>

          <CheckboxGroup
            title="Nos options"
            checkboxes={sportOptions}
            checkboxState={checkboxState}
            onCheckboxChange={onCheckboxChange}
          />

          <div className="flex justify-center pt-4">
            <button
                type="button"
                onClick={resetFilters} 
              className="py-1 px-2 bg-main-blue text-white rounded-[10px] text-center text-[12px] font-quicksand hover:opacity-80"
            >
              Réinitialiser les filtres
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
