import React from "react";
import {
  AccesSports,
  BienEtreDeveloppement,
  ImageStyle,
  PourquoiKetKet,
} from "./About";

const Apropos = ({
  about,
  aboutImage1,
  aboutImage2,
  aboutImage3,
  aboutImage5,
}) => {
  return (
    <div className=" mt-3 mb-11 w-[90%] mx-auto pe-4 max-sm:w-full max-sm:px-5 max-sm:mt-8">
      <div className=" h-[100%]   relative  mt-[5.1%] ">
        <ImageStyle />
        <PourquoiKetKet about={about} aboutImage1={aboutImage1} />
        <AccesSports
          about={about}
          aboutImage2={aboutImage2}
          aboutImage3={aboutImage3}
        />
        <BienEtreDeveloppement about={about} aboutImage5={aboutImage5} />
      </div>
    </div>
  );
};

export default Apropos;
