import React from "react";
import { NavLink } from "react-router-dom";

const FollowUs = ({ datas }) => {
  return (
    <div className="text- pt-3 px-6 flex items-center gap-6 ">
      {datas?.map((data, index) => (
        <NavLink
          key={index}
          to={data.link}
          className="hover:text-slate-800 space-x-  font-semibold text-sm"
        >
          <span>{data.icon}</span>
        </NavLink>
      ))}
    </div>
  );
};

export default FollowUs;
