import React, { useState } from "react";
import InputLabel from "./InputLabel";
import { SelectComponent } from "./SelectComponent";
import { TextareaLabel } from "./TextareaLabel";
import InputPhone from "./InputPhone";

export const FormPartenaire = ({
  formState,
  handleChange,
  phone,
  setPhone,
  setFormState,
}) => {
  const options = [
    "select",
    "1 à 10",
    "de 11 à 50",
    "de 51 à 100",
    "de 101 à 201",
    "de 201 à 500",
    "de 501 à 1000",
    "de 1001 à 1500",
    "plus de 1500",
  ];

  const metiers = [
    "select",
    "Propriétaire d'une salle de sport",
    "Gérant d’une salle de sport",
    "Coach/Responsable d’une salle de sport",
    "Enseignant de sports de relaxation et de bien-être",
    "Enseignant de sports de combat",
    "Enseignant de sports collectifs",
    "Autres",
  ];
  return (
    <>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="prenom"
          value={formState.prenom}
          onChange={handleChange}
          children="Prenom"
        />
        <InputLabel
          type="text"
          name="nom_etablissement"
          value={formState.nom_etablissement}
          onChange={handleChange}
          children="Noms de votre structure"
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="nom"
          value={formState.nom}
          onChange={handleChange}
          children="Nom"
        />
        <div className="flex flex-col w-full md:w-1/2">
          <SelectComponent
            name="metier"
            children="Votre métier... "
            options={metiers}
            value={formState.metier}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="email"
          name="mail"
          value={formState.mail}
          onChange={handleChange}
          children="E-mail"
        />
        <InputLabel
          type="text"
          name="position_hierarchique"
          value={formState.position_hierarchique}
          onChange={handleChange}
          children="Votre position dans la structure "
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <div className="mb-2 sm:flex flex-col w-full md:w-1/2 gap-3 ">
          <InputPhone
            phone={phone}
            setPhone={setPhone}
            label={"Numéro de téléphone"}
          />
          <div className="flex flex-col w-full">
            {/* <SelectComponent
              name="date"
              children="Rendez-vous"
              options={options}
              // value={formState.date}
              // onChange={handleChange}
            /> */}
            <InputLabel
              type="date"
              name="rendez_vous"
              value={formState.rendez_vous}
              onChange={handleChange}
              children="Rendez-vous"
            />
          </div>
        </div>
        <TextareaLabel
          label="Description de votre structure"
          placeholder="eg: Listez les activités proposées et une brève description de votre structure"
          value={formState.description_etablissement}
          onChange={handleChange}
          name="description_etablissement"
        />
      </div>
    </>
  );
};
