import React from "react";
import { Link } from "react-router-dom";
import Bouton from "./Bouton";

export const RechercheSection = () => {
  return (
    <section className=" md:mx-auto w-full md:w-[70%] py-4 px-6 md:px-0  flex flex-col md:items-center md:justify-center  ">
      <p className="md:text-center   text-sm md:text-md font-quicksand font-semibold leading-[28px]">
        Découvrez notre page de recherche dédiée, où vous pouvez explorer une
        vaste sélection de clubs de sport, de salles de sport, ainsi que des
        cours de fitness et de bien-être proposés par Ket Ket. Trouvez
        facilement les meilleures options pour répondre à vos besoins sportifs
        et de bien-être, tout en bénéficiant de notre expertise pour simplifier
        votre recherche
      </p>
      <Link
        to={"/salle"}
        className=" mt-7 mx-auto flex items-center justify-center"
      >
        <Bouton
          label="Recherche"
          className=" bg-main-blue font-semibold py-2  hover:text-main-blue hover:bg-white hover:border-2 hover:border-[#1D428A] text-h6  font-r-mono text-white text-center text-sm transition-all duration-500 ease-in-out rounded-[12px] "
        />
      </Link>
    </section>
  );
};
