import React, { useState } from "react";
import { useAddNewsletterMutation } from "../../api/subscribeNewsletter/hooks";
import { toast, ToastContainer } from "react-toastify";

const NewsletterForm = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: addNewSubcription } = useAddNewsletterMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Veuillez remplir le champ svp.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("L'adresse e-mail est invalide.");
      return false;
    }
    try {
      setIsLoading(true);
      await addNewSubcription({ email });
      toast.success(
        "Vous êtes inscrit à la newsletter ! Veuillez verifie votre mail"
      );
      setEmail("");
    } catch (error) {
      console.log(error);
      
      const message =
        error?.message || "Une erreur est survenue. Veuillez réessayer.";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col px-4 max-w-sm mx-auto">
      <h2 className="mb-4 text-sm font-semibold text-gray-900 uppercase">
        S'abonner à la Newsletter
      </h2>
      <form
        onSubmit={handleSubmit}
        className="w-full flex justify-between items-center bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Votre adresse e-mail"
          required
          className="w-3/4 py-3 px-4 outline-none rounded-l-md text-gray-700
            focus:ring-[1px] focus:ring-blue-600 focus:border-blue-600
            transition-all duration-300 ease-in-out"
        />
        <button
          type="submit"
          disabled={isLoading}
          className={`w-1/4 py-3.5 font-semibold bg-main-blue text-white text-sm rounded-r-md hover:bg-main-blue focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 ${
            isLoading ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          {isLoading ? (
            <div className="flex">
              <span>Envoi...</span>
              <div className="border-4 border-solid border-blue-300 border-t-4 border-t-blue-800 rounded-full w-5 h-5  animate-spin ms-2"></div>
            </div>
          ) : (
            "S'inscrire"
          )}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default NewsletterForm;
