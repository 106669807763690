import React, { useEffect } from "react";
import Hero from "../components/hero";
import Apropos from "../components/partenaires/Apropos";
import Advantage from "../components/advantage";
import Service from "../components/service";
import Activities from "../components/activity";
import { Footer } from "../components/Footer";
import ListeEntreprises from "../components/partenaires/ListeEntreprises";
import { useClientsQuery } from "../api/clients/hooks";

const Homepage = () => {
  const { data: clients = [], isLoading } = useClientsQuery();

  return (
    <section className=" max-md:pt-0">
      <Hero />
      <Apropos />
      <div className="py-10 max-md:py-0">
        <ListeEntreprises isLoading={isLoading} clientsData={clients?.data} />
      </div>
      <Advantage />
      <Service />
      <div className="mb-5">
        <Activities />
      </div>
      <Footer />
    </section>
  );
};

export default Homepage;
